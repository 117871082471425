import React, { useContext, useEffect, useState } from "react"

//Components
import { Footer, FooterCheckout, Loader } from "components/anti"
import NavbarWrapper from "./navbar-wrapper"
import FullLoadingCover from "./global/full-loading-cover"
import Cookies from "js-cookie"
import { AsyncApollo } from "lib/functions"
import { Q_SEARCH_CITY_SUBDISTRICT } from "lib/graphql"
import { SET_CITY_SUBDISTRICT } from "lib/redux/type"
import { useDispatch } from "react-redux"

const Layout = ({ children, ...props }) => {
  // const { initialLoading } = useContext(LoadingContext)
  const currency = Cookies.get(process.env.CURRENCY)
  const dispatch = useDispatch()

  // Mobile viewport height workaround
  // prevent quirky behaviour on mobile when cover is set to 100vh
  // useEffect(() => {
  //   const loadingBanner = document.querySelector(".loading-page")
  //   if (loadingBanner && currency) {
  //     loadingBanner.remove()
  //   }
  // }, [currency])

  const changeHeight = () => {
    let vh = window.innerHeight * 0.01
    document.documentElement.style.setProperty("--vh", `${vh}px`)
  }
  useEffect(() => {
    changeHeight()
    window.addEventListener("resize", changeHeight)
    return () => window.removeEventListener("resize", changeHeight)
  }, [])

  useEffect(async () => {
    try {
      let { data } = await AsyncApollo({
        query: Q_SEARCH_CITY_SUBDISTRICT,
      })
      if (data?.searchCitySubdistrict) {
        dispatch({
          type: SET_CITY_SUBDISTRICT,
          payload: data?.searchCitySubdistrict,
        })
      }
    } catch (error) {}
  }, [])

  return (
    <>
      <NavbarWrapper {...props} />
      <main>
        {children}
        {props?.noFooter ? null : <Footer type={props?.footerType ?? 2} />}
      </main>
    </>
  )
}

export default Layout
