import React from "react"

//Components
import Lottie from "react-lottie"
import loadingJSON from "assets/lottie/loading.json"
import Cookies from "js-cookie"

const FullLoadingCover = ({ currency, show }) => {
  return (
    <div className={`loading-page ${show ? "show" : "hide"}`}>
      <div className="img-lottie">
        <Lottie
          options={{
            loop: true,
            autoplay: true,
            animationData: loadingJSON,
            rendererSettings: {
              preserveAspectRatio: "xMidYMid slice",
            },
          }}
        />
      </div>
    </div>
  )
}

export default FullLoadingCover
